


















import Vue from "vue";
export default Vue.extend({
  name: "Login",
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkToken() {
      this.$store.dispatch("CHECK_TOKEN");
    },
  },
  beforeMount() {
    this.checkToken();
  },
});
