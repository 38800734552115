var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[_vm._l((_vm.prices),function(price,priceIndex){return [_vm._l((price.costs),function(cost,costIndex){return [_vm._l((cost.cost),function(cost_,costIndex_){return [_c('div',{key:priceIndex + costIndex + costIndex_},[_c('v-list-item',[_c('v-list-item-content',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(price.code.toUpperCase())}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Layanan: ' + cost.service)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Deskripsi: ' + cost.description)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Estimasi: ' + cost_.etd + ' Hari')}})],1),_c('v-col',{staticClass:"my-auto"},[_c('p',{staticClass:"text-h6 mb-0"},[_vm._v(_vm._s(_vm.toRupiah(cost_.value)))]),(cost_.note)?_c('small',[_vm._v("Note: "+_vm._s(cost_.note))]):_vm._e()])],1)],1),_c('v-list-item-action',{staticClass:"my-auto"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"},on:{"click":function($event){_vm.copy(
                      price.code.toUpperCase(),
                      cost.service,
                      cost.description,
                      cost_.etd + ' Hari',
                      _vm.toRupiah(cost_.value),
                      cost_.note
                    )}}},[_vm._v("mdi-content-copy")])],1),_c('input',{ref:"container",refInFor:true,attrs:{"type":"hidden"}})],1)],1),_c('v-divider')],1)]})]})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }